<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <router-link to="/log"><md-button class="md-raised">Quay lại</md-button></router-link>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="row">
                    <div class="col l-12 m-12 c-12">
                         <md-field>
                             <label for="title">Tiêu đề</label>
                             <md-input name="title" :disabled="true" v-model="entity.title" ></md-input>
                         </md-field>

                         <md-field>
                             <label for="requestUrl">Request Url</label>
                             <md-input name="requestUrl" :disabled="true" v-model="entity.requestUrl" ></md-input>
                         </md-field>

                         <md-field>
                             <label for="requestData">Request Data</label>
                             <md-textarea  name="requestData" :disabled="true" v-model="entity.requestData" ></md-textarea>
                         </md-field>

                         <md-field>
                             <label for="errorMessage">Error Message</label>
                             <md-textarea  name="errorMessage" :disabled="true" v-model="entity.errorMessage" ></md-textarea>
                         </md-field>

                    </div>
                </div>
            </div>
        </div>
   </div>
</template>
<script>
    import logService from '../../../api/logService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';

    export default ({
        metaInfo: {
            title: 'Thêm/Cập nhật log'
        },
        data() {
            return {
               title: '',
               id: 0,
               submitted: false,
               entity: { id: 0, title: '', requestUrl: '', requestData: '', errorMessage: '', },
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Thông tin log';
               this.getById();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            submit(){
               this.submitted = true;
               this.$v.$touch();
               if (this.$v.$invalid) {
                   return;
               }
               if(this.id > 0){
                   this.edit();
               }
               else{
                   this.add();
               }
            },

            add(){
               this.setLoading(true);
               logService.add(this.entity).then((response) => {
                   if(response.statusCode == 200){
                       messageBox.showMessage("Thêm mới thành công");
                   }
                   else{
                       messageBox.showWarning(response.data);
                   }
                }).finally(() => { this.setLoading(false); });
            },

            edit(){
               this.setLoading(true);
               logService.edit(this.entity).then((response) => {
                   if(response.statusCode == 200){
                       messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/log');
                   }
                   else{
                       messageBox.showWarning(response.data);
                   }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
               this.setLoading(true);
               logService.getById(this.id).then((response) => {
                   if(response.statusCode == 200){
                       this.entity = response.data;
                   }
                   else{
                       messageBox.showWarning(response.data);
                   }
                }).finally(() => { this.setLoading(false); });
            },

        },
        validations: {
             entity: {
                id: { required },
                createdAt: { required },
             },
        },
    })

</script>
